import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
import BlogPage from '../components/pages/BlogPage';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Blog`}</h1>
    <BlogPage mdxType="BlogPage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      